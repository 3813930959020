@use '@strategies/tokens/layout';

.Process {

    .image-wrap {
        img {
            padding-top: layout.$padding--large * 2;
            max-width: 100%;
        }
    }

    

}
